import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { jwtDecode as jwt } from 'jwt-decode';

// RegistrationForm and UserDetailsForm components
import RegisterForm from './RegisterForm/RegisterForm';
import UserDetailsForm from './UserDetailsForm/UserDetailsForm';

// Constants
import {
  APP_CONSTANTS as Constants,
  API_ENDPOINTS,
  SECRET_API_KEY,
  ROUTES,
} from 'constants/Constants';

// Services
import { registerUserService } from 'services/UserService';
import {
  getUploadURLService,
  fetchCategoriesService,
} from 'services/CommonService';

// Types
import { RegisterFormType, UserDetailsType } from 'interfaces/Register.types';

// Context
import GlobalContext from 'contexts/Global.context';

// Utils
import { camelToSnake, validateURL, getFileName } from 'utils/UtilityFunctions';
import { Category } from 'interfaces/Category.types';

const {
  errorConstants: {
    EMAIL_IS_NOT_VALID,
    EMAIL_NOT_ENTERED_ERROR,
    USER_NAME_NOT_ENTERED,
    USER_NAME_INVALID,
    PASSWORD_CHAR_LENGTH_ERROR,
    PASSWORD_NOT_ENTERED_ERROR,
    CONFIRM_PASSWORD_ERROR,
    PASSWORDS_MISMATCH_ERROR,
  },

  regExValidators: { EMAIL_VALIDATOR_REGEX, USER_NAME_VALIDATOR },
} = Constants;

const USER_INFO_ERRORS: any = {
  firstName: 'Please enter your first name',
  preferredTitle: 'Please enter your preferred title',
  lastName: 'Please enter your last name',
  industry: 'Please enter the industry',
  website: 'Please enter a valid website URL',
  goals: 'Please select your goals',
  professionalClassification: 'Please select your professional classification',
};

const USER_REGISTER_VALIDATIONS: any = {
  userName: {
    min: 1,
    max: 30,
    error: 'Please enter characters between 1 and 30',
  },
  password: {
    min: 8,
    max: 50,
    error: 'Please enter characters between 1 and 20',
  },
};

const USER_DETAILS_VALIDATIONS: any = {
  firstName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  preferredTitle: {
    min: 1,
    max: 30,
    error: 'Please enter characters between 1 and 30',
  },
  lastName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  userName: {
    min: 1,
    max: 30,
    error: 'Please enter characters between 1 and 30',
  },
  bio: {
    min: 1,
    max: 10000,
    error: 'Bio cannot be longer than 10,000 characters',
  },
  /* about: {
    min: 1,
    max: 300,
    error: 'Please enter characters between 1 and 300',
  }, */
  city: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  state: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  zip: {
    min: 5,
    max: 6,
    error: 'Please enter characters between 5 and 6',
  },
};

const Register: React.FunctionComponent = () => {
  const {
    setAuth,
    isLoggedIn,
    userDetails: storedUser,
    variantDetails,
  } = useContext(GlobalContext);

  const [step, setStep] = useState<number>(1);

  const { search } = useLocation();

  const { utm_source } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const [registerFormData, setRegisterFormData] = useState<RegisterFormType>({
    email: '',
    userName: '',
    password: '',
  });

  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [registerFormErrors, setRegisterFormErrors] = useState<
    RegisterFormType | any
  >({
    email: '',
    userName: '',
    password: '',
    confirmPassword: '',
  });

  const [validating, setValidating] = useState<boolean>(false);
  const [validateApiError, setValidateApiError] = useState<string>('');

  const [userDetails, setUserDetails] = useState<UserDetailsType>({
    firstName: '',
    lastName: '',
    bio: '',
    /* about: '', */
    careerCategories: '',
    opportunityCategories: '',
    eduTrainingCategories: '',
    industry: '',
    city: '',
    state: '',
    zip: '',
    website: '',
    facebook: '',
    twitter: '',
    youtube: '',
    instagram: '',
    additionalLink1: { title: '', link: '' },
    additionalLink2: { title: '', link: '' },
    additionalLink3: { title: '', link: '' },
    additionalLink4: { title: '', link: '' },
    additionalLink5: { title: '', link: '' },
    companies: [
      {
        title: '',
        name: '',
        date_range: '',
      },
      {
        title: '',
        name: '',
        date_range: '',
      },
      {
        title: '',
        name: '',
        date_range: '',
      },
    ],
    schools: [
      {
        name: '',
        notes: '',
        level: '',
      },
      {
        name: '',
        notes: '',
        level: '',
      },
      {
        name: '',
        notes: '',
        level: '',
      },
    ],
    goals: '',
    otherGoals: '',
    phoneType: '',
    phone: '',
    alternateEmail: '',
    professionalClassification: '',
    professionalClassificationOther: '',
    practiceType: '',
    preferredTitle: '',
    speciality: '',
    specialityHospital: '',
    specialityCity: '',
    specialityState: '',
    fellowship: '',
    fellowshipSub: '',
    fellowshipHospital: '',
    fellowshipSubCity: '',
    fellowshipSubState: '',
    licensedStates: '',
  });

  const [photo, setPhoto] = useState<null | File>(null);

  const [resumeFile, setResumeFile] = useState<null | File>(null);

  const [userDetailsErrors, setUserDetailsErrors] = useState<any>({
    firstName: '',
    lastName: '',
    bio: '',
    /* about: '', */
    city: '',
    state: '',
    industry: '',
    zip: '',
    additionalLink1Title: '',
    additionalLink2Title: '',
    additionalLink3Title: '',
    additionalLink4Title: '',
    additionalLink5Title: '',
    additionalLink1: '',
    additionalLink2: '',
    additionalLink3: '',
    additionalLink4: '',
    additionalLink5: '',
    professionalClassification: '',
    practiceType: '',
    goals: '',
    /* additionalLinks: ['', ''], */
  });

  const [registerLoading, setRegisterLoading] = useState<boolean>(false);
  const [registerApiError, setRegisterApiError] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  const [categories, setCategories] = useState<Array<Category | any>>([]);

  /**
   * Handler that gets called when the text in input field changes
   * @param {Object} event The event object
   */
  const handleRegisterInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;

    if (name === 'email') {
      if (!value) {
        setRegisterFormErrors({
          ...registerFormErrors,
          [name]: EMAIL_NOT_ENTERED_ERROR,
        });
      } else {
        if (!EMAIL_VALIDATOR_REGEX.test(value)) {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: EMAIL_IS_NOT_VALID,
          });
        } else {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: '',
          });
        }
      }
    }

    if (name === 'userName') {
      if (!value) {
        setRegisterFormErrors({
          ...registerFormErrors,
          [name]: USER_NAME_NOT_ENTERED,
        });
      } else {
        if (!USER_NAME_VALIDATOR.test(value)) {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: USER_NAME_INVALID,
          });
        } else {
          if (
            USER_REGISTER_VALIDATIONS[name] &&
            value.length > USER_REGISTER_VALIDATIONS[name].max
          ) {
            setRegisterFormErrors({
              ...registerFormErrors,
              [name]: USER_REGISTER_VALIDATIONS[name].error,
            });
          } else {
            setRegisterFormErrors({
              ...registerFormErrors,
              [name]: '',
            });
          }
        }
      }
    }

    if (name === 'password') {
      if (!value) {
        setRegisterFormErrors({
          ...registerFormErrors,
          [name]: PASSWORD_NOT_ENTERED_ERROR,
        });
      } else {
        if (value.length < 8) {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: PASSWORD_CHAR_LENGTH_ERROR,
          });
        } else {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: '',
          });
        }
      }
    }

    if (name === 'confirmPassword') {
      if (!value) {
        setRegisterFormErrors({
          ...registerFormErrors,
          [name]: CONFIRM_PASSWORD_ERROR,
        });
      } else {
        if (value !== registerFormData.password) {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: PASSWORDS_MISMATCH_ERROR,
          });
        } else {
          setRegisterFormErrors({
            ...registerFormErrors,
            [name]: '',
          });
        }
      }
    }

    if (name !== 'confirmPassword') {
      setRegisterFormData({
        ...registerFormData,
        [name]: value,
      });
    } else {
      setConfirmPassword(value);
    }
  };

  const uploadPhoto = async () => {
    let uploadImageResponse: any = null;
    let errorOccurred: any = null;
    if (photo) {
      const fileInfo = {
        name: getFileName(photo.name),
        type: photo.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        uploadImageResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: photo,
        });

        if (uploadImageResponse) {
          setUserDetails({
            ...userDetails,
            photo: url,
          });
        }
        return { uploadImageResponse, url };
      } else if (error) {
        setRegisterApiError(
          `An error occurred while uploading photo: ${error}`,
        );
        errorOccurred = error;
        return { error };
      }
    }
    return { uploadImageResponse, error: errorOccurred };
  };

  const uploadResume = async () => {
    if (resumeFile) {
      const fileInfo = {
        name: getFileName(resumeFile.name),
        type: resumeFile.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        const uploadResumeResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: resumeFile,
        });

        if (uploadResumeResponse) {
          setUserDetails({
            ...userDetails,
            photo: url,
          });
        }
        return { uploadResumeResponse, url };
      } else if (error) {
        setRegisterApiError(
          `An error occurred while uploading photo: ${error}`,
        );
        return { error };
      }
    }
  };

  const callAnalytics = (token: string) => {
    const user: any = jwt(token);
    window.analytics.identify({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      username: user.user_name,
      id: user.id,
      userId: user.id,
    });
  };

  const registration = async (photoURL?: string, resumeURL?: string) => {
    setRegisterLoading(true);
    const consolidatedRequestBody: any = {
      ...registerFormData,
      ...userDetails,
    };
    const reducer = (acc: any, current: string) => {
      const newObj: any = {};
      if (
        current !== 'additionalLink1' &&
        current !== 'additionalLink2' &&
        current !== 'additionalLink3' &&
        current !== 'additionalLink4' &&
        current !== 'additionalLink5'
      ) {
        newObj[camelToSnake(current)] = consolidatedRequestBody[current];
      }
      return { ...acc, ...newObj };
    };
    const requestBody = Object.keys({
      ...registerFormData,
      ...userDetails,
    }).reduce(reducer, {});
    if (
      !userDetails.additionalLink1 &&
      !userDetails.additionalLink2 &&
      !userDetails.additionalLink3 &&
      !userDetails.additionalLink4 &&
      !userDetails.additionalLink5
    ) {
      requestBody.additional_links = [];
    } else {
      const links = [
        userDetails.additionalLink1,
        userDetails.additionalLink2,
        userDetails.additionalLink3,
        userDetails.additionalLink4,
        userDetails.additionalLink5,
      ];
      requestBody.additional_links = links.filter((l) => l.title && l.link);
      requestBody.additional_links = requestBody.additional_links.map(
        (item) => ({
          title: item.title,
          link: item.link.toLowerCase(),
        }),
      );
    }

    requestBody.schools = requestBody.schools.filter((l) => l.level && l.name);

    requestBody.companies = requestBody.companies.filter(
      (l) => l.title && l.name && l.date_range,
    );

    if (requestBody.twitter) {
      requestBody.twitter = requestBody.twitter.toLowerCase();
    }

    if (requestBody.website) {
      requestBody.website = requestBody.website.toLowerCase();
    }

    if (requestBody.youtube) {
      requestBody.youtube = requestBody.youtube.toLowerCase();
    }

    if (requestBody.facebook) {
      requestBody.facebook = requestBody.facebook.toLowerCase();
    }

    if (requestBody.instagram) {
      requestBody.instagram = requestBody.instagram.toLowerCase();
    }

    const response = await registerUserService({
      ...requestBody,
      photo: photoURL,
      resume: resumeURL,
      utm_source: utm_source || null,
    });

    if (!response.ok) {
      const error = await response.clone().text();
      setRegisterApiError(error);
    } else {
      const { token, id } = await response.json();
      callAnalytics(token);
      setAuth(token);
      setUserId(id);
      const userDetails: any = jwt(token);
      if (
        userDetails.variant_host &&
        !window.location.href.includes(userDetails.variant_host) &&
        !window.location.href.includes('localhost')
      ) {
        window.location.href = `https://${userDetails.variant_host}/dashboard`;
      }
    }
    setRegisterLoading(false);
  };

  /**
   * The below function gets called once the user submits the form
   */
  const registerUser = async () => {
    setRegisterApiError('');
    setRegisterLoading(true);
    if (photo && resumeFile) {
      // first try uploading photo and then upload resume
      const {
        uploadImageResponse,
        url: photoUrl,
        error,
      }: any = await uploadPhoto();
      if (uploadImageResponse) {
        const {
          uploadResumeResponse,
          url: resumeUrl,
          error,
        }: any = await uploadResume();
        if (uploadResumeResponse) {
          await registration(photoUrl, resumeUrl);
        } else if (error) {
          return;
        }
      } else if (error) {
        return;
      }
    } else if (photo && !resumeFile) {
      const { uploadImageResponse, url: photoUrl }: any = await uploadPhoto();
      if (uploadImageResponse) {
        await registration(photoUrl, '');
      }
    } else if (!photo && resumeFile) {
      const { uploadResumeResponse, url: resumeUrl }: any =
        await uploadResume();
      if (uploadResumeResponse) {
        await registration('', resumeUrl);
      }
    } else {
      await registration('', '');
    }

    setRegisterLoading(false);
  };

  const validateRegisterForm = () => {
    const errors = { ...registerFormErrors };
    const { email, userName, password } = registerFormData;
    Object.keys({ ...registerFormData, confirmPassword }).forEach((name) => {
      if (name === 'email') {
        if (!email) {
          errors[name] = EMAIL_NOT_ENTERED_ERROR;
        } else {
          if (!EMAIL_VALIDATOR_REGEX.test(email)) {
            errors[name] = EMAIL_IS_NOT_VALID;
          } else {
            errors[name] = '';
          }
        }
      } else if (name === 'userName') {
        if (!userName) {
          errors[name] = USER_NAME_NOT_ENTERED;
        } else {
          if (!USER_NAME_VALIDATOR.test(userName)) {
            errors[name] = USER_NAME_INVALID;
          } else {
            if (
              USER_REGISTER_VALIDATIONS[name] &&
              userName.length > USER_REGISTER_VALIDATIONS[name].max
            ) {
              errors[name] = USER_REGISTER_VALIDATIONS[name].error;
            } else {
              errors[name] = '';
            }
          }
        }
      } else if (name === 'password') {
        if (!password) {
          errors[name] = PASSWORD_NOT_ENTERED_ERROR;
        } else {
          if (password.length < 8) {
            errors[name] = PASSWORD_CHAR_LENGTH_ERROR;
          } else {
            errors[name] = '';
          }
        }
      } else if (name === 'confirmPassword') {
        if (!confirmPassword) {
          errors[name] = CONFIRM_PASSWORD_ERROR;
        } else {
          if (confirmPassword !== registerFormData.password) {
            errors[name] = PASSWORDS_MISMATCH_ERROR;
          } else {
            errors[name] = '';
          }
        }
      }
    });

    setRegisterFormErrors(errors);

    if (Object.keys(errors).some((key) => errors[key])) {
      return false;
    } else {
      return true;
    }
  };

  const validateDetailsForm = () => {
    const {
      firstName,
      lastName,
      industry,
      preferredTitle,
      professionalClassification,
      goals,
    } = userDetails;
    const errors = { ...userDetailsErrors };
    const requiredDetails = {
      firstName,
      lastName,
      industry,
      preferredTitle,
      professionalClassification,
      goals,
    };

    Object.keys(requiredDetails).forEach((name) => {
      if (name === 'industry') {
        if (!userDetails[name] && variantDetails?.site_short_title === 'CCE') {
          errors[name] = USER_INFO_ERRORS[name];
        } else {
          errors[name] = '';
        }
      } else {
        if (!userDetails[name]) {
          errors[name] = USER_INFO_ERRORS[name];
        } else {
          errors[name] = '';
        }
      }
    });

    setUserDetailsErrors(errors);
    if (Object.keys(errors).some((key) => errors[key])) {
      setRegisterApiError(
        'There are some errors above, please fix those to proceed.',
      );
      return false;
    } else {
      return true;
    }
  };

  /**
   * @param {Object} errorObject Error object from API
   * Create an error message from error Object received from the API
   */
  const createAnErrorMessage = (errorObject: any) => {
    const reducer = (acc: string, currentVal: string) =>
      `${errorObject[currentVal]} ${acc}`;
    return Object.keys(errorObject).reduce(reducer, '');
  };

  /**
   * The below method checks whether the userName and email ID entered
   * by user are unique by making a call to the backend
   */
  const validateCredentials = async () => {
    setValidateApiError('');
    setValidating(true);
    const response = await fetch(API_ENDPOINTS.VALIDATE_REGISTRATION_DATA, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'x-api-key': SECRET_API_KEY,
      },
      method: 'POST',
      body: JSON.stringify({
        user_name: registerFormData.userName,
        email: registerFormData.email,
        password: registerFormData.password,
      }),
    });

    if (!response.ok) {
      setValidateApiError(await response.clone().text());
      return false;
    } else {
      const credentialsError = await response.json();
      const { user_name, email, password } = credentialsError;
      if (!user_name && !email && !password) {
        return true;
      } else {
        setValidateApiError(createAnErrorMessage(credentialsError));
      }
    }
    setValidating(false);
    return false;
  };

  const goToNextStep = async () => {
    if (validateRegisterForm()) {
      const isValidData = await validateCredentials();
      if (isValidData) {
        setStep(2);
      }
    }
  };

  /**
   * Handler that gets called when the text in input field changes
   * @param {Object} event The event object
   */
  const handleUserDetailsInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    if (name === 'fellowship') {
      setUserDetails({
        ...userDetails,
        [name]: value,
        fellowshipSub: '',
      });
    }
    if (!value) {
      if (name !== 'industry' || variantDetails?.site_short_title === 'CCE') {
        setUserDetailsErrors({
          ...userDetailsErrors,
          [name]: USER_INFO_ERRORS[name],
        });
      }
    } else {
      if (USER_DETAILS_VALIDATIONS[name]) {
        if (
          value.length > USER_DETAILS_VALIDATIONS[name].max ||
          value.length < USER_DETAILS_VALIDATIONS[name].min
        ) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [name]: USER_DETAILS_VALIDATIONS[name].error,
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [name]: '',
          });
        }
      } else {
        setUserDetailsErrors({
          ...userDetailsErrors,
          [name]: '',
        });
      }
    }

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleCategorySelect = (categoryList: Array<Category>, key: string) => {
    const selectedCategories = categoryList.map((list) => list.name);

    setUserDetails({
      ...userDetails,
      [key]: selectedCategories.join(','),
    });
  };

  const handleMultiSelect = (list: any, key: string) => {
    const selected = list.map((list) => list.label);

    setUserDetails({
      ...userDetails,
      [key]: selected.join('_'),
    });

    if (!selected.length && USER_INFO_ERRORS[key]) {
      setUserDetailsErrors({
        ...userDetailsErrors,
        [key]: USER_INFO_ERRORS[key],
      });
    } else {
      setUserDetailsErrors({
        ...userDetailsErrors,
        [key]: '',
      });
    }
  };

  /**
   * Handler that gets called when the text in website/social links input field is changed
   * @param {Object} event The event object
   */
  const handleWebsiteInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    if (value) {
      if (validateURL(value)) {
        setUserDetailsErrors({
          ...userDetailsErrors,
          [name]: '',
        });
      } else {
        setUserDetailsErrors({
          ...userDetailsErrors,
          [name]: USER_INFO_ERRORS['website'],
        });
      }
    } else {
      setUserDetailsErrors({
        ...userDetailsErrors,
        [name]: '',
      });
    }

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handlePhotoChange = (image: File | null) => {
    setPhoto(image);
  };

  const handleResumeChange = (resume: File | null) => {
    setResumeFile(resume);
  };

  const handleAdditionalLinksChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;

    if (name.includes('Title')) {
      const linkKey = name.split('Title')[0];
      if (value) {
        if (!userDetails[linkKey].link) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [linkKey]: 'Please enter URL',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [linkKey]: validateURL(userDetails[linkKey].link)
              ? ''
              : USER_INFO_ERRORS['website'],
            [name]: '',
          });
        }
      } else {
        if (!userDetails[linkKey].link) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [linkKey]: '',
            [name]: '',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [linkKey]: validateURL(userDetails[linkKey].link)
              ? ''
              : USER_INFO_ERRORS['website'],
            [name]: 'Please enter title',
          });
        }
      }

      setUserDetails({
        ...userDetails,
        [linkKey]: {
          ...userDetails[linkKey],
          title: value,
        },
      });
    } else {
      const titleKey = `${name}Title`;
      if (value) {
        if (!userDetails[name].title) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [titleKey]: 'Please enter title',
            [name]: validateURL(value) ? '' : USER_INFO_ERRORS['website'],
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [titleKey]: '',
            [name]: validateURL(value) ? '' : USER_INFO_ERRORS['website'],
          });
        }
      } else {
        if (!userDetails[name].title) {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [titleKey]: '',
            [name]: '',
          });
        } else {
          setUserDetailsErrors({
            ...userDetailsErrors,
            [titleKey]: '',
            [name]: 'Please enter URL',
          });
        }
      }

      setUserDetails({
        ...userDetails,
        [name]: {
          ...userDetails[name],
          link: value,
        },
      });
    }
  };

  const handleCompanyChange = (event: any, index: number) => {
    const { name, value } = event.target;
    setUserDetails({
      ...userDetails,
      companies: userDetails.companies.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return { ...item, [name]: value };
      }),
    });
  };

  const handleSchoolChange = (event: any, index: number) => {
    const { name, value } = event.target;
    setUserDetails({
      ...userDetails,
      schools: userDetails.schools.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return { ...item, [name]: value };
      }),
    });
  };

  /**
   * The function gets called when user submits the form or click on submit button
   */
  const handleSubmit = () => {
    if (validateDetailsForm()) {
      registerUser();
    }
  };

  const fetchCategories = useCallback(async () => {
    const categories: Array<Category> = await fetchCategoriesService();

    if (categories && categories.length) {
      setCategories(categories);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  if (isLoggedIn && userId) {
    return <Redirect to={`${ROUTES.PROFILE}/${storedUser.slug}?confirm=1`} />;
  }

  const renderRegistrationForm = () => {
    return (
      <RegisterForm
        registerFormData={{ ...registerFormData, confirmPassword }}
        registerFormErrors={registerFormErrors}
        handleInputChange={handleRegisterInputChange}
        loading={validating}
        apiError={validateApiError}
        goToNextStep={goToNextStep}
      />
    );
  };

  const renderUserDetailsForm = () => {
    return (
      <UserDetailsForm
        categories={categories}
        userDetails={userDetails}
        userDetailsErrors={userDetailsErrors}
        handleInputChange={handleUserDetailsInputChange}
        handleCategorySelect={handleCategorySelect}
        handleWebsiteInputChange={handleWebsiteInputChange}
        handleAdditionalLinksChange={handleAdditionalLinksChange}
        handlePhotoChange={handlePhotoChange}
        handleMultiSelect={handleMultiSelect}
        handleResumeChange={handleResumeChange}
        loading={registerLoading}
        apiError={registerApiError}
        handleSubmit={handleSubmit}
        handleCompanyChange={handleCompanyChange}
        handleSchoolChange={handleSchoolChange}
      />
    );
  };

  const pageToBeShown = () => {
    switch (step) {
      case 1:
        return renderRegistrationForm();

      case 2:
        return renderUserDetailsForm();

      default:
        return renderRegistrationForm();
    }
  };

  return <>{pageToBeShown()}</>;
};

export default React.memo(Register);
