import React, { useContext } from 'react';

// Components
import Checkbox from 'components/Form/Checkbox/Checkbox';

import INDUSTRIES from 'config/Industries';

// Context
import GlobalContext from 'contexts/Global.context';

// SCSS
import styles from './Sidebar.module.scss';
import { JOB_TYPE } from 'constants/Constants';

interface Query {
  type: string;
  expectedNeed?: string;
  payment?: Array<Array<number>>;
  location?: string;
  deadline?: string;
  licensedState?: string;
  experience?: string;
  fee?: string;
  industry?: string;
  forMeOnly?: boolean;
}

interface FilterProps {
  title: string;
  type: string;
  checkboxValues: Query | any;
  onCategoryFilterChange: () => void;
  onPaymentFilterChange: (value: Array<Array<number>>) => void;
  onLocationFilterChange: (location: Array<string>) => void;
  onDeadlineFilterChange: (options: Array<string>) => void;
  onLicenseFilterChange: (options: Array<string>) => void;
  onExperienceFilterChange: (exp: Array<string>) => void;
  onExpectedNeedChange: (need: Array<string>) => void;
  onRateChange: (need: Array<string>) => void;
  onIndustryChange: (industry: Array<string>) => void;
  onCostFilterChange: (fee: Array<string>) => void;
}

const EXPECTED_NEED = [
  { id: 'fullTime', label: 'Full Time', name: 'full time' },
  { id: 'partTime', label: 'Part Time', name: 'part time' },
  { id: 'onetime', label: 'One Time', name: 'one time' },
  { id: 'freelance', label: 'Freelance', name: 'freelance' },
  { id: 'internship', label: 'Internship', name: 'internship' },
];

const EXPECTED_NEED_OPPORTUNITY = [
  { id: 'fullTime', label: 'Full Time', name: 'full time' },
  { id: 'partTime', label: 'Part Time', name: 'part time' },
  { id: 'onetime', label: 'One Time', name: 'one time' },
  { id: 'freelance', label: 'Freelance', name: 'freelance' },
];

const RATE = [
  { id: 'salary1', label: 'Salary', name: 'salary' },
  { id: 'flat', label: 'Flat Rate', name: 'flat' },
  { id: 'hour', label: 'Per Hour', name: 'hour' },
  { id: 'free', label: 'Non-Paying', name: 'free' },
  { id: 'TBD', label: 'TBD', name: 'TBD' },
];

const PAY_OPTIONS = [
  { id: 'tbd', label: 'TBD', value: 'TBD' },
  { id: 'noCharge', label: 'No Charge', value: 'free' },
  { id: 'less1k', label: '<1k', value: [0, 1000] },
  { id: '1kto10k', label: '1k - 10k', value: [1000, 10000] },
  { id: '10kto100k', label: '10k - 100k', value: [10000, 100000] },
  { id: '100kplus', label: '100k+', value: [100000, 999999999] },
];

const LOCATION_OPTIONS = [
  { id: 'onSite', label: 'On-Site', name: 'on site' },
  { id: 'remote', label: 'Remote', name: 'remote' },
  { id: 'hybrid', label: 'Hybrid', name: 'both' },
  { id: 'location_tbd', label: 'TBD', name: 'TBD' },
];

const COST_OPTIONS = [
  { id: 'free_fee', label: 'Free', name: 'free' },
  { id: 'paid_fee', label: 'Paid', name: 'paid' },
];

const STATE_LICENSE_OPTIONS = [
  { id: 'yes_license', label: 'Yes', name: 'yes' },
  { id: 'no_license', label: 'No', name: 'no' },
];

const DUE_DATE_OPTIONS = [
  { id: 'asap', label: 'ASAP', name: 'asap' },
  { id: 'no_due_date', label: 'No Due Date', name: 'no due date' },
  { id: 'tbd_due_date', label: 'TBD', name: 'TBD' },
  { id: 'specific_date', label: 'Specific Date', name: 'specific' },
];

const EXPERIENCE_OPTIONS = [
  {
    id: 'expert',
    label: 'Required',
    name: 'Experience/Training: Required (Verified Credential)',
  },
  {
    id: 'some',
    label: 'Preferred',
    name: 'Experience/Training: Preferred (Not Required)',
  },
  {
    id: 'none',
    label: 'None',
    name: 'Experience/Training: No Requirement/Preference',
  },
];

const EXPERIENCE_OPTIONS_TALENT = [
  {
    id: 'expert',
    label: 'Expert',
    name: 'Verified Expert/Credential Included',
  },
  {
    id: 'some',
    label: 'Some',
    name: 'Some Experience/Not Credential',
  },
  {
    id: 'none',
    label: 'None',
    name: 'No Experience/Not Credential',
  },
];

let HASH: any = {};

PAY_OPTIONS.forEach((pay, index) => {
  HASH[index] = index;
});

const Filters = (props: FilterProps) => {
  const { isLoggedIn } = useContext(GlobalContext);

  const isCheckboxChecked = (parentType: string, name?: any) => {
    if (name && typeof name === 'object') {
      return JSON.stringify(props.checkboxValues[parentType]).includes(
        JSON.stringify(name),
      );
    }
    return props.checkboxValues[parentType]?.includes(name);
  };

  const handleExpectedNeedChange = (event: any) => {
    const { name } = event.target;
    const { expectedNeed } = props.checkboxValues;

    let arr = expectedNeed && expectedNeed.length ? expectedNeed : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onExpectedNeedChange(arr);
  };

  const handleRateChange = (event: any) => {
    const { name } = event.target;
    const { rate } = props.checkboxValues;

    let arr = rate && rate.length ? rate : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onRateChange(arr);
  };

  const handleLocationChange = (event: any) => {
    const { name } = event.target;
    const { location } = props.checkboxValues;

    let arr = location && location.length ? location : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onLocationFilterChange(arr);
  };

  const handleExperienceChange = (event: any) => {
    const { name } = event.target;
    const { experience } = props.checkboxValues;

    let arr = experience && experience.length ? experience : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onExperienceFilterChange(arr);
  };

  const handleDeadlineChange = (event: any) => {
    const { name } = event.target;
    const { deadline } = props.checkboxValues;

    let arr = deadline && deadline.length ? deadline : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onDeadlineFilterChange(arr);
  };

  const handleLicenseChange = (event: any) => {
    const { name } = event.target;
    const { licensedState } = props.checkboxValues;

    let arr = licensedState && licensedState.length ? licensedState : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onLicenseFilterChange(arr);
  };

  const handleIndustryChange = (event: any) => {
    const { name } = event.target;
    const { industry } = props.checkboxValues;

    let arr = industry?.length ? industry : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onIndustryChange(arr);
  };

  const handleCostChange = (event: any) => {
    const { name } = event.target;
    const { fee } = props.checkboxValues;

    let arr = fee && fee.length ? fee : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onCostFilterChange(arr);
  };

  const handlePayChange = (event: any) => {
    const { id } = event.target;
    const { pay } = props.checkboxValues;
    const newArr = pay.map((p: any) => p) || [];
    const filterValue = PAY_OPTIONS.filter((pay) => pay.id === id)[0].value;
    const hashObj: any = {};
    for (let i = 0; i < pay.length; i++) {
      hashObj[pay[i]] = i;
    }

    // check if the value is already present in checkbox values
    const isValuePresent = Object.keys(hashObj).length
      ? // eslint-disable-next-line no-prototype-builtins
        hashObj.hasOwnProperty(filterValue)
      : false;
    if (isValuePresent) {
      // remove if filter is already selected
      const index = hashObj[filterValue.toString()];
      newArr.splice(index, 1);
      props.onPaymentFilterChange(newArr);
    } else {
      newArr.push(filterValue);
      props.onPaymentFilterChange(newArr);
    }
  };

  const renderExpectedNeedCheckboxes = (type: string) => {
    return (
      <div>
        {(type === 'career' ? EXPECTED_NEED : EXPECTED_NEED_OPPORTUNITY).map(
          (need) => (
            <Checkbox
              key={need.id}
              id={need.id}
              label={need.label}
              name={need.name}
              isChecked={isCheckboxChecked('expectedNeed', need.name)}
              onChange={handleExpectedNeedChange}
            />
          ),
        )}
      </div>
    );
  };

  const renderIndustryCheckboxes = () => {
    return (
      <div>
        {INDUSTRIES.map((need) => (
          <Checkbox
            key={need.label.replace(/\s/g, '_')}
            id={need.label.replace(/\s/g, '_')}
            label={need.label}
            name={need.label}
            isChecked={isCheckboxChecked('industry', need.label)}
            onChange={handleIndustryChange}
          />
        ))}
      </div>
    );
  };

  const renderRateCheckboxes = () => {
    return (
      <div>
        {RATE.map((need) => (
          <Checkbox
            key={need.id}
            id={need.id}
            label={need.label}
            name={need.name}
            isChecked={isCheckboxChecked('rate', need.name)}
            onChange={handleRateChange}
          />
        ))}
      </div>
    );
  };

  const renderCostCheckboxes = () => {
    return (
      <div>
        {COST_OPTIONS.map((need) => (
          <Checkbox
            key={need.id}
            id={need.id}
            label={need.label}
            name={need.name}
            isChecked={isCheckboxChecked('fee', need.name)}
            onChange={handleCostChange}
          />
        ))}
      </div>
    );
  };

  const renderPaymentCheckboxes = () => {
    return (
      <div>
        {PAY_OPTIONS.map((pay) => (
          <Checkbox
            key={pay.id}
            id={pay.id}
            label={
              pay.value === 'free'
                ? props.type === JOB_TYPE.career
                  ? 'Non-Paying'
                  : 'No Cost'
                : pay.label
            }
            isChecked={isCheckboxChecked('pay', pay.value)}
            onChange={handlePayChange}
          />
        ))}
      </div>
    );
  };

  const renderCategoryCheckbox = () => {
    return (
      <div>
        <Checkbox
          id="forMeOnly"
          label="Listings For Me"
          name="forMeOnly"
          onChange={props.onCategoryFilterChange}
          isChecked={!!props.checkboxValues['forMeOnly']}
        />
      </div>
    );
  };

  const renderLocationCheckboxes = () => {
    return (
      <div>
        {LOCATION_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.id}
            id={loc.id}
            label={loc.label}
            name={loc.name}
            isChecked={isCheckboxChecked('location', loc.name)}
            onChange={handleLocationChange}
          />
        ))}
      </div>
    );
  };

  const renderLicenseCheckboxes = () => {
    return (
      <div>
        {STATE_LICENSE_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.id}
            id={loc.id}
            label={loc.label}
            name={loc.name}
            isChecked={isCheckboxChecked('licensedState', loc.name)}
            onChange={handleLicenseChange}
          />
        ))}
      </div>
    );
  };

  const renderExperienceCheckboxes = () => {
    return (
      <div>
        {(props.type !== 'career'
          ? EXPERIENCE_OPTIONS_TALENT
          : EXPERIENCE_OPTIONS
        ).map((loc) => (
          <Checkbox
            key={loc.id}
            id={loc.id}
            label={loc.label}
            name={loc.name}
            isChecked={isCheckboxChecked('experience', loc.name)}
            onChange={handleExperienceChange}
          />
        ))}
      </div>
    );
  };

  const renderDeadlineCheckboxes = () => {
    return (
      <div>
        {DUE_DATE_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.id}
            id={loc.id}
            label={loc.label}
            name={loc.name}
            isChecked={isCheckboxChecked('deadline', loc.name)}
            onChange={handleDeadlineChange}
          />
        ))}
      </div>
    );
  };

  const Accordion = ({ title, children }) => {
    const [openFilter, setOpenFilter] = React.useState(false);
    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title ${openFilter ? '' : 'open'}`}
          onClick={() => setOpenFilter(!openFilter)}
        >
          {title}
        </div>
        <div className={`accordion-item ${!openFilter ? '' : 'collapsed'}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.sidebarData}>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarDescription}>{props.title}</div>
      </div>

      {isLoggedIn && (
        <div className={`${styles.sidebarBlock}`}>
          <Accordion title="For Me">{renderCategoryCheckbox()}</Accordion>
        </div>
      )}

      {false && (
        <div className={`${styles.sidebarBlock}`}>
          <Accordion title="Position">
            {renderExpectedNeedCheckboxes(props.type)}
          </Accordion>
        </div>
      )}

      {!!INDUSTRIES?.length && (
        <div className={`${styles.sidebarBlock}`}>
          <Accordion title="Industry">{renderIndustryCheckboxes()}</Accordion>
        </div>
      )}

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Payment">{renderPaymentCheckboxes()}</Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Location">{renderLocationCheckboxes()}</Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Due Date">{renderDeadlineCheckboxes()}</Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion
          title={props.type === 'career' ? 'Credentials' : 'Experience'}
        >
          {renderExperienceCheckboxes()}
        </Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="State Licensed Required">
          {renderLicenseCheckboxes()}
        </Accordion>
      </div>

      {false && (
        <div className={`${styles.sidebarBlock}`}>
          <Accordion
            title={props.type !== 'career' ? 'Compensation Rate' : 'Rate'}
          >
            {renderRateCheckboxes()}
          </Accordion>
        </div>
      )}

      {props.type !== 'career' && (
        <div className={`${styles.sidebarBlock}`}>
          <Accordion title="Service Fee">{renderCostCheckboxes()}</Accordion>
        </div>
      )}
    </div>
  );
};

export default React.memo(Filters);
