import React, { useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  // Tooltip,
  // OverlayTrigger,
} from 'react-bootstrap';

// Components
import Button from 'components/Button/Button';
import Input from 'components/Form/Input/Input';
import TextArea from 'components/Form/TextArea/TextArea';
import FileUpload from 'components/FileUpload/FileUpload';
import Select from 'components/Form/Select/Select';

// Context
import GlobalContext from 'contexts/Global.context';

import { Multiselect } from 'multiselect-react-dropdown';

// Interfaces
import { UserDetailsType } from 'interfaces/Register.types';

// Constants
import { FILE_TYPE, ROUTES } from 'constants/Constants';
import INDUSTRIES from 'config/Industries';
import STATES from 'constants/States';
import { PHONE_TYPES, FREE_WORK_CREDENTIALS } from 'constants/Constants';
import {
  EDUCATIONS,
  GOALS,
  SPECIALITIES,
  QUALIFICATIONS,
  FELLOWSHIPS,
  FELLOWSHIP_SUBSPECIALITIES,
  PRACTICE_TYPES,
} from 'constants/Educations';

// SCSS
import styles from './UserDetailsForm.module.scss';
import { Category } from 'interfaces/Category.types';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

interface Props {
  categories?: Array<Category>;
  userDetails: UserDetailsType;
  userDetailsErrors: any;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleCategorySelect: (list: Array<any>, key: string) => void;
  handleMultiSelect: (list: Array<any>, key: string) => void;
  handleWebsiteInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleAdditionalLinksChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handlePhotoChange: (photo: File | null) => void;
  handleResumeChange: (resume: File | null) => void;
  handleSchoolChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  handleCompanyChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  handleSubmit: () => void;
  loading: boolean;
  apiError?: string;
}

// const renderTooltipCost = (props: any) => (
//   <Tooltip
//     className={styles.tooltipContainer}
//     id="button-tooltipCost"
//     {...props}
//   >
//     Categories are how we sort {COPY_OVERRIDES?.profile?.careers || 'Careers'}{' '}
//     and {COPY_OVERRIDES?.profile?.opportunities || 'Opportunities'}, and also
//     how users display their skills on their profiles. Select the Categories you
//     want to showcase for hiring/getting hired.
//   </Tooltip>
// );

const HTTP_PLACEHOLDER = 'http://';

const UserDetailsForm: React.FunctionComponent<Props> = ({
  categories,
  userDetails,
  userDetailsErrors,
  handleInputChange,
  handleCategorySelect,
  handleMultiSelect,
  handleWebsiteInputChange,
  handleAdditionalLinksChange,
  handlePhotoChange,
  // handleResumeChange,
  handleSchoolChange,
  handleCompanyChange,
  handleSubmit,
  loading,
  apiError,
}: Props) => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <Container className={`mb-5 ${styles.registerContinued}`} fluid>
      <Container className="p-0">
        <Row className="my-5">
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerTitle}>Sign Up</div>
            <div className={styles.registerSubTitle}>
              Last step before you complete your profile.
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerIntro}>
              Almost finished, just need some final information so we can create
              a proper profile.
            </div>
            <div className={`${styles.requiredFieldsNote} text-right`}>
              <span>* </span>= Required Fields
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Goals & Interests</div>
              <div className={styles.registerSubLabel}>
                How do you intend to use the site? This will help us provide you
                with the best experience. This information can be edited/updated
                at any time.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Goals (Check all that apply)
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Multiselect
                    options={GOALS || []}
                    isObject={true}
                    displayValue="label"
                    closeOnSelect={false}
                    showCheckbox
                    onSelect={(list) => handleMultiSelect(list, 'goals')}
                    onRemove={(list) => handleMultiSelect(list, 'goals')}
                  />
                  {userDetailsErrors.goals && (
                    <div className={styles.error}>
                      {userDetailsErrors.goals}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Other Goals (only if not listed above)
                  </div>
                  <Input
                    name="otherGoals"
                    type="text"
                    value={userDetails.otherGoals}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.otherGoals ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.otherGoals && (
                    <div className={styles.error}>
                      {userDetailsErrors.otherGoals}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    {COPY_OVERRIDES?.profile?.career_interests ||
                      'Career categories I am interested in'}
                    {/* <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipCost}
                    >
                      <div className={styles.infoHover}>i</div>
                    </OverlayTrigger>
                    <span className={styles.shortMess}>
                      <a
                        href={
                          COPY_OVERRIDES?.profile?.submit_category_link ||
                          'https://musiccareermap.com/category-submission'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              COPY_OVERRIDES?.profile?.submit_category_text ||
                              'If you don&apos;t see your category listed, click here to submit it.',
                          }}
                        ></span>
                      </a>
                    </span> */}
                  </div>
                  <Multiselect
                    options={categories || []}
                    isObject={true}
                    displayValue="name"
                    closeOnSelect={false}
                    showCheckbox
                    onSelect={(categories) =>
                      handleCategorySelect(categories, 'careerCategories')
                    }
                    onRemove={(categories) =>
                      handleCategorySelect(categories, 'careerCategories')
                    }
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    {COPY_OVERRIDES?.profile?.opportunity_interests ||
                      'Opportunities categories I am interested in'}
                    {/* <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipCost}
                    >
                      <div className={styles.infoHover}>i</div>
                    </OverlayTrigger>
                    <span className={styles.shortMess}>
                      <a
                        href={
                          COPY_OVERRIDES?.profile?.submit_category_link ||
                          'https://musiccareermap.com/category-submission'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              COPY_OVERRIDES?.profile?.submit_category_text ||
                              'If you don&apos;t see your category listed, click here to submit it.',
                          }}
                        ></span>
                      </a>
                    </span> */}
                  </div>
                  <Multiselect
                    options={categories || []}
                    isObject={true}
                    displayValue="name"
                    showCheckbox
                    closeOnSelect={false}
                    onSelect={(categories) =>
                      handleCategorySelect(categories, 'opportunityCategories')
                    }
                    onRemove={(categories) =>
                      handleCategorySelect(categories, 'opportunityCategories')
                    }
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Seeking Education/Training: For those wanting to learn new
                    skills and earn training credentials (that can help you get
                    hired), please list categories of interest (can be updated
                    anytime).
                  </div>
                  <Multiselect
                    options={categories || []}
                    isObject={true}
                    displayValue="name"
                    showCheckbox
                    closeOnSelect={false}
                    onSelect={(categories) =>
                      handleCategorySelect(categories, 'eduTrainingCategories')
                    }
                    onRemove={(categories) =>
                      handleCategorySelect(categories, 'eduTrainingCategories')
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Personal Information</div>
              <div className={styles.registerSubLabel}>
                Tell us about yourself.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    First Name
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="firstName"
                    type="text"
                    value={userDetails.firstName}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.firstName ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.firstName && (
                    <div className={styles.error}>
                      {userDetailsErrors.firstName}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Last Name
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="lastName"
                    type="text"
                    value={userDetails.lastName}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.lastName ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.lastName && (
                    <div className={styles.error}>
                      {userDetailsErrors.lastName}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Preferred Professional Name/Title
                    <span className={styles.requiredField}> *</span>
                    <div>
                      (What would you like to appear on your profile? (Example:
                      Dr. Jane Smith))
                    </div>
                  </div>
                  <Input
                    name="preferredTitle"
                    type="text"
                    value={userDetails.preferredTitle}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.preferredTitle ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.preferredTitle && (
                    <div className={styles.error}>
                      {userDetailsErrors.preferredTitle}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Professional Classification
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Select
                    id="professionalClassification"
                    selectItems={QUALIFICATIONS}
                    onChange={handleInputChange}
                    name="professionalClassification"
                    value={userDetails.professionalClassification}
                  />
                  {userDetailsErrors.professionalClassification && (
                    <div className={styles.error}>
                      {userDetailsErrors.professionalClassification}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Professional Classification Other (Only if not listed above)
                  </div>
                  <Input
                    name="professionalClassificationOther"
                    type="text"
                    value={userDetails.professionalClassificationOther}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.professionalClassificationOther
                        ? styles.errorInput
                        : ''
                    }
                  />
                  {userDetailsErrors.professionalClassificationOther && (
                    <div className={styles.error}>
                      {userDetailsErrors.professionalClassificationOther}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Practice/Employment Type
                    <div>(In which kind of practice do you work?)</div>
                  </div>
                  <Select
                    id="practiceType"
                    selectItems={PRACTICE_TYPES}
                    onChange={handleInputChange}
                    name="practiceType"
                    value={userDetails.practiceType}
                  />
                  {userDetailsErrors.practiceType && (
                    <div className={styles.error}>
                      {userDetailsErrors.practiceType}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>City</div>
                  <Input
                    name="city"
                    type="text"
                    value={userDetails.city}
                    onChange={handleInputChange}
                    className={userDetailsErrors.city ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.city && (
                    <div className={styles.error}>{userDetailsErrors.city}</div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>State</div>
                  <Select
                    id="state"
                    selectItems={STATES}
                    onChange={handleInputChange}
                    name="state"
                    value={userDetails.state}
                  />
                  {userDetailsErrors.state && (
                    <div className={styles.error}>
                      {userDetailsErrors.state}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Zip</div>
                  <Input
                    name="zip"
                    type="text"
                    value={userDetails.zip || ''}
                    onChange={handleInputChange}
                    className={userDetailsErrors.zip ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.zip && (
                    <div className={styles.error}>{userDetailsErrors.zip}</div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Bio
                    <div>
                      <b>
                        Tell our community about yourself (10k character limit).{' '}
                        <a
                          href="https://beyondphysician.org/bio/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Click here
                        </a>{' '}
                        for help.
                      </b>
                    </div>
                  </div>
                  <TextArea
                    name="bio"
                    value={userDetails.bio}
                    onChange={handleInputChange}
                    className={userDetailsErrors.bio ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.bio && (
                    <div className={styles.error}>{userDetailsErrors.bio}</div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Phone Type (Not publicly visible)
                  </div>
                  <Select
                    id="phoneType"
                    selectItems={PHONE_TYPES}
                    onChange={handleInputChange}
                    name="phoneType"
                    value={userDetails.phoneType}
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Phone Number (Not publicly visible)
                  </div>
                  <Input
                    name="phone"
                    type="text"
                    value={userDetails.phone}
                    onChange={handleInputChange}
                    placeholder="(___) ___ - ____"
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Alternate Email (Not publicly visible)
                  </div>
                  <Input
                    name="alternateEmail"
                    type="email"
                    value={userDetails.alternateEmail}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              {!!INDUSTRIES?.length && (
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Industry
                      {variantDetails?.site_short_title === 'CCE' && (
                        <span className={styles.requiredField}> *</span>
                      )}
                    </div>
                    <Select
                      id="industry"
                      selectItems={INDUSTRIES}
                      onChange={handleInputChange}
                      name="industry"
                      value={userDetails.industry}
                    />
                    {userDetailsErrors.industry &&
                      variantDetails?.site_short_title === 'CCE' && (
                        <div className={styles.error}>
                          {userDetailsErrors.industry}
                        </div>
                      )}
                  </div>
                </Col>
              )}
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Upload Your Photo</div>
                  <FileUpload
                    fileType={FILE_TYPE.IMAGE}
                    fileSpecs={['5mb max', '500px wide', '500px tall']}
                    onFileChange={handlePhotoChange}
                  />
                </div>
              </Col>
              {/* <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    About
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <TextArea
                    name="about"
                    value={userDetails.about}
                    onChange={handleInputChange}
                    className={userDetailsErrors.about ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.about && (
                    <div className={styles.error}>
                      {userDetailsErrors.about}
                    </div>
                  )}
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>
                Professional Information
              </div>
              <div className={styles.registerSubLabel}>
                Tell us about your career.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Residency/Specialty
                    <div>
                      (Please choose one if applicable, and{' '}
                      <a
                        href="http://beyondphysician.org/residency-category-submission"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        click here
                      </a>{' '}
                      if your preference is not listed.)
                    </div>
                  </div>
                  <Select
                    id="speciality"
                    selectItems={SPECIALITIES}
                    onChange={handleInputChange}
                    name="speciality"
                    value={userDetails.speciality}
                  />
                  {userDetailsErrors.speciality && (
                    <div className={styles.error}>
                      {userDetailsErrors.speciality}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Residency/Specialty Hospital/Clinic Name
                  </div>
                  <Input
                    name="specialityHospital"
                    type="text"
                    value={userDetails.specialityHospital}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.specialityHospital
                        ? styles.errorInput
                        : ''
                    }
                  />
                  {userDetailsErrors.specialityHospital && (
                    <div className={styles.error}>
                      {userDetailsErrors.specialityHospital}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authLabel}>
                  Residency/Specialty Location
                </div>
              </Col>
              <Col xl={8} lg={8} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>City</div>
                  <Input
                    name="specialityCity"
                    type="text"
                    value={userDetails.specialityCity}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.specialityCity ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.specialityCity && (
                    <div className={styles.error}>
                      {userDetailsErrors.specialityCity}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>State</div>
                  <Select
                    id="specialityState"
                    selectItems={STATES}
                    onChange={handleInputChange}
                    name="specialityState"
                    value={userDetails.specialityState}
                  />
                  {userDetailsErrors.specialityState && (
                    <div className={styles.error}>
                      {userDetailsErrors.specialityState}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Fellowship Category{' '}
                    <div>
                      (Please choose one, if applicable.{' '}
                      <a
                        href={
                          COPY_OVERRIDES?.profile?.submit_fellowship_link ||
                          'https://musiccareermap.com/category-submission'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              COPY_OVERRIDES?.profile?.submit_fellowship_text ||
                              'Click here',
                          }}
                        ></span>
                      </a>{' '}
                      if your option is not listed.)
                    </div>
                  </div>
                  <Select
                    id="fellowship"
                    selectItems={FELLOWSHIPS}
                    onChange={handleInputChange}
                    name="fellowship"
                    value={userDetails.fellowship}
                  />
                  {userDetailsErrors.fellowship && (
                    <div className={styles.error}>
                      {userDetailsErrors.fellowship}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Fellowship Subspecialty{' '}
                    <div>
                      (After selecting your Category above, please choose one
                      subcategory.{' '}
                      <a
                        href={
                          COPY_OVERRIDES?.profile?.submit_fellowship_sub_link ||
                          'https://musiccareermap.com/category-submission'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              COPY_OVERRIDES?.profile?.submit_fellowship_text ||
                              'Click here',
                          }}
                        ></span>
                      </a>{' '}
                      if your option is not listed.)
                    </div>
                  </div>
                  <Select
                    id="fellowshipSub"
                    selectItems={
                      FELLOWSHIP_SUBSPECIALITIES[userDetails.fellowship] || []
                    }
                    onChange={handleInputChange}
                    name="fellowshipSub"
                    value={userDetails.fellowshipSub}
                  />
                  {userDetailsErrors.fellowshipSub && (
                    <div className={styles.error}>
                      {userDetailsErrors.fellowshipSub}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Fellowship Subspecialty Hospital/Clinic Name
                  </div>
                  <Input
                    name="fellowshipHospital"
                    type="text"
                    value={userDetails.fellowshipHospital}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.fellowshipHospital
                        ? styles.errorInput
                        : ''
                    }
                  />
                  {userDetailsErrors.fellowshipHospital && (
                    <div className={styles.error}>
                      {userDetailsErrors.fellowshipHospital}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authLabel}>
                  Fellowship Subspecialty Location
                </div>
              </Col>
              <Col xl={8} lg={8} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>City</div>
                  <Input
                    name="fellowshipSubCity"
                    type="text"
                    value={userDetails.fellowshipSubCity}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.fellowshipSubCity
                        ? styles.errorInput
                        : ''
                    }
                  />
                  {userDetailsErrors.fellowshipSubCity && (
                    <div className={styles.error}>
                      {userDetailsErrors.fellowshipSubCity}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>State</div>
                  <Select
                    id="fellowshipSubState"
                    selectItems={STATES}
                    onChange={handleInputChange}
                    name="fellowshipSubState"
                    value={userDetails.fellowshipSubState}
                  />
                  {userDetailsErrors.fellowshipSubState && (
                    <div className={styles.error}>
                      {userDetailsErrors.fellowshipSubState}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Licensed States</div>
                  <Multiselect
                    options={STATES || []}
                    isObject={true}
                    displayValue="label"
                    showCheckbox
                    closeOnSelect={false}
                    onSelect={(list) =>
                      handleMultiSelect(list, 'licensedStates')
                    }
                    onRemove={(list) =>
                      handleMultiSelect(list, 'licensedStates')
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Employment History</div>
              <div className={styles.registerSubLabel}>
                To help connect you with the best jobs and services available.
                Include any information outside of these fields in your About
                section. Start with your most recent/current employer, which
                will appear at the top of your list.
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            {userDetails.companies.map((item, i) => {
              return (
                <Row key={i}>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.subHeading}>Company #{i + 1}</div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Name</div>
                      <Input
                        name="name"
                        type="text"
                        value={userDetails.companies[i].name}
                        onChange={(e) => handleCompanyChange(e, i)}
                      />
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Title</div>
                      <Input
                        name="title"
                        type="text"
                        value={userDetails.companies[i].title}
                        onChange={(e) => handleCompanyChange(e, i)}
                      />
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Date Range</div>
                      <Input
                        name="date_range"
                        type="text"
                        value={userDetails.companies[i].date_range}
                        onChange={(e) => handleCompanyChange(e, i)}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Education</div>
              <div className={styles.registerSubLabel}>
                To help connect you with the best jobs and services available.
                Include any information outside of these fields in your About
                section. Start with your most recent/current education, which
                will appear at the top of your list.
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            {userDetails.schools?.map((item, i) => {
              return (
                <Row key={i}>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.subHeading}>School #{i + 1}</div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>
                        Level{' '}
                        <span style={{ fontWeight: 'normal' }}>
                          (Degree, etc)
                        </span>
                      </div>
                      <Select
                        id="level"
                        name="level"
                        selectItems={EDUCATIONS}
                        onChange={(e) => handleSchoolChange(e, i)}
                        value={userDetails.schools[i].level}
                      />
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Institution Name</div>
                      <Input
                        name="name"
                        type="text"
                        value={userDetails.schools[i].name}
                        onChange={(e) => handleSchoolChange(e, i)}
                      />
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>
                        Notes{' '}
                        <span style={{ fontWeight: 'normal' }}>
                          (Field of Study, Honors, etc)
                        </span>
                      </div>
                      <Input
                        name="notes"
                        type="text"
                        value={userDetails.schools[i].notes}
                        onChange={(e) => handleSchoolChange(e, i)}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Add Additional Links</div>
              <div className={styles.registerSubLabel}>
                Personal or Business. Use this section to include any awards,
                publications, research, trials.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Website</div>
                  <Input
                    name="website"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.website}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.website ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.website && (
                    <div className={styles.error}>
                      {userDetailsErrors.website}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Facebook</div>
                  <Input
                    name="facebook"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.facebook}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.facebook ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.facebook && (
                    <div className={styles.error}>
                      {userDetailsErrors.facebook}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Twitter</div>
                  <Input
                    name="twitter"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.twitter}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.twitter ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.twitter && (
                    <div className={styles.error}>
                      {userDetailsErrors.twitter}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>YouTube</div>
                  <Input
                    name="youtube"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.youtube}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.youtube ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.youtube && (
                    <div className={styles.error}>
                      {userDetailsErrors.youtube}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Instagram</div>
                  <Input
                    name="instagram"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.instagram}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.instagram ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.instagram && (
                    <div className={styles.error}>
                      {userDetailsErrors.instagram}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Additional Links</div>
                  <Row>
                    <Col xl={4} lg={4} md={12} sm={12}>
                      <Input
                        name="additionalLink1Title"
                        type="text"
                        placeholder="Title"
                        value={userDetails.additionalLink1.title}
                        onChange={handleAdditionalLinksChange}
                        className={
                          userDetailsErrors.additionalLink1Title
                            ? styles.errorInput
                            : ''
                        }
                      />
                      {userDetailsErrors.additionalLink1Title && (
                        <div className={styles.error}>
                          {userDetailsErrors.additionalLink1Title}
                        </div>
                      )}
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12}>
                      <Input
                        name="additionalLink1"
                        type="text"
                        placeholder={HTTP_PLACEHOLDER}
                        value={userDetails.additionalLink1.link}
                        onChange={handleAdditionalLinksChange}
                        className={
                          userDetailsErrors.additionalLink1
                            ? styles.errorInput
                            : ''
                        }
                      />
                      {userDetailsErrors.additionalLink1 && (
                        <div className={styles.error}>
                          {userDetailsErrors.additionalLink1}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <div className={`${styles.authInput} mt-3`}>
                    <Row>
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink2Title"
                          type="text"
                          placeholder="Title"
                          value={userDetails.additionalLink2.title}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink2Title
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink2Title && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink2Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink2"
                          type="text"
                          placeholder={HTTP_PLACEHOLDER}
                          value={userDetails.additionalLink2.link}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink2
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink2 && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink2}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className={`${styles.authInput} mt-3`}>
                    <Row>
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink3Title"
                          type="text"
                          placeholder="Title"
                          value={userDetails.additionalLink3.title}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink3Title
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink3Title && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink3Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink3"
                          type="text"
                          placeholder={HTTP_PLACEHOLDER}
                          value={userDetails.additionalLink3.link}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink3
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink3 && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink3}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className={`${styles.authInput} mt-3`}>
                    <Row>
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink4Title"
                          type="text"
                          placeholder="Title"
                          value={userDetails.additionalLink4.title}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink4Title
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink4Title && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink4Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink4"
                          type="text"
                          placeholder={HTTP_PLACEHOLDER}
                          value={userDetails.additionalLink4.link}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink4
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink4 && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink4}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className={`${styles.authInput} mt-3`}>
                    <Row>
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink5Title"
                          type="text"
                          placeholder="Title"
                          value={userDetails.additionalLink5.title}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink5Title
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink5Title && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink5Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink5"
                          type="text"
                          placeholder={HTTP_PLACEHOLDER}
                          value={userDetails.additionalLink5.link}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink5
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink5 && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink5}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Credentials</div>
              <div className={styles.registerSubLabel}>
                Adding credentials is a quick way to enhance and distinguish
                your skills and experience.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row className={styles.authButton}>
              {/* <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Upload Your Resume</div>
                  <FileUpload
                    fileType={FILE_TYPE.DOCUMENT}
                    fileSpecs={['pdf and doc files only', '5mbs max']}
                    onFileChange={handleResumeChange}
                  />
                </div>
              </Col> */}
              <Col xl={12} lg={12} md={12} sm={12}>
                <a
                  href={FREE_WORK_CREDENTIALS}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn-grey-ghost"
                >
                  Free Work/Skill Credentials: Click Here
                </a>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className={styles.signUpTerms}
              >
                By signing up you are agreeing to our{' '}
                <a
                  href={ROUTES.TERMS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                &amp;{' '}
                <a
                  href={ROUTES.PRIVACY}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy.
                </a>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Button
                  type="large w-100"
                  label={loading ? 'loading...' : 'Finish My Account'}
                  disabled={loading}
                  onClick={handleSubmit}
                ></Button>
                <p className={styles.loginNote}>
                  Please note: You may be required to login a second time if you
                  have joined Beyond Physician through one of our partners who
                  utilizes a private-label version.
                </p>
                {apiError && <Alert variant="danger">{apiError}</Alert>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(UserDetailsForm);
