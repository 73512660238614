import React, { Fragment, useContext } from 'react';
import moment from 'moment';
// import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { JOB_TYPE } from 'constants/Constants';

// SCSS
import styles from './Sidebar.module.scss';
import GlobalContext from 'contexts/Global.context';

interface Props {
  postedDate?: string;
  location?: string;
  referenceLinks?: Array<string>;
  category: string;
  fee?: number;
  type: string;
  dueDate?: string;
  idealApplicant?: string;
  experience?: string;
  speciality?: string;
  fellowship?: string;
  fellowshipSub?: string;
  licensedState?: string;
}

const Sidebar: React.FC<Props> = ({
  postedDate,
  dueDate,
  idealApplicant,
  experience,
  speciality,
  fellowship,
  fellowshipSub,
  licensedState,
  category,
  type,
}: // positionType,
// location,
// referenceLinks,
// category,
// fee,
// type,
Props) => {
  // const renderTooltipCom = (props: any) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Paid Opportunities are services where the applicant pays the posting party
  //     (outside of MIC), while Free Opportunities do not require any payment.
  //   </Tooltip>
  // );
  const { variantDetails } = useContext(GlobalContext);
  const academyURL =
    variantDetails?.academy_url || 'http://beyondphysician.academy';

  return (
    <Fragment>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Posted</div>
        <div className={styles.sidebarResult}>
          {moment(postedDate).fromNow()}
        </div>
      </div>
      {type === JOB_TYPE.career && (
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarLabel}>Deadline</div>
          <div className={styles.sidebarResult}>{dueDate || 'No Due Date'}</div>
        </div>
      )}
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>
          Ideal {type === 'career' ? 'Applicant' : 'Client'}{' '}
        </div>
        <div className={styles.sidebarResult}>{idealApplicant}</div>
      </div>
      {type === 'career' && category && (
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarLabel}>Category</div>
          <div className={styles.sidebarResult}>
            <a
              target="_blank"
              className={styles.skill}
              rel="noopener noreferrer"
              href={`${academyURL}/certifications?category=${category}`}
            >
              {category}
            </a>
          </div>
        </div>
      )}
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Residency/Speciality</div>
        <div className={styles.sidebarResult}>
          {speciality || 'Not Specific'}
        </div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Fellowship/Subspecialty</div>
        <div className={styles.sidebarResult}>
          {fellowshipSub || fellowship || 'Not Specific'}
        </div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>
          Licensed {type === 'career' ? 'State' : 'States'}
        </div>
        <div className={styles.sidebarResult}>
          {licensedState || 'Not Specific'}
        </div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Experience</div>
        <div className={styles.sidebarResult}>{experience}</div>
      </div>
      {/* <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Type</div>
        <div className={styles.sidebarResult}>{positionType || 'N/A'}</div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Location</div>
        <div className={styles.sidebarResult}>{location}</div>
      </div>
      {type === JOB_TYPE.opportunity && (
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarLabel}>
            Service Fee
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipCom}
            >
              <div className={styles.infoHover}>i</div>
            </OverlayTrigger>
          </div>
          <div className={styles.sidebarResult}>{fee ? `$${fee}` : 'Free'}</div>
        </div>
      )}
      {referenceLinks && referenceLinks.length > 0 ? (
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarLabel}>Links</div>
          <div className={styles.sidebarResult}>
            {referenceLinks.map((link) => (
              <div key={link} className={styles.referenceLink}>
                <a
                  key={link}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link}
                </a>
              </div>
            ))}
          </div>
        </div>
      ) : null} */}
    </Fragment>
  );
};

export default React.memo(Sidebar);
