import React, { Fragment, useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { Helmet } from 'react-helmet';

// SVGs
import BadgeGrey from '../../../../assets/svgs/badgeGrey.svg';

// Interfaces
import { ApplicantProfile } from 'interfaces/User.types';

import { deleteCertificateService } from 'services/UserService';

// Components
import Credentials from 'components/Credentials/Credentials';

// import { FREE_WORK_CREDENTIALS } from 'constants/Constants';

import COPY_OVERRIDES from 'config/CopyOverrides';

// Styles
import styles from './Resume.module.scss';
import GlobalContext from 'contexts/Global.context';

interface Props {
  profile: ApplicantProfile;
  refetch: () => void;
}

const Resume: React.FC<Props> = ({ profile, refetch }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { variantDetails } = useContext(GlobalContext);

  const academyURL =
    variantDetails?.academy_url || 'http://beyondphysician.academy';

  const deleteCertificate = async (id: string) => {
    setIsLoading(true);
    const { success } = await deleteCertificateService(id);
    if (success) {
      refetch();
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Helmet>
          {COPY_OVERRIDES?.titles?.credentials && (
            <title>{COPY_OVERRIDES?.titles?.credentials}</title>
          )}
          {COPY_OVERRIDES?.meta?.credentials && (
            <meta
              name="description"
              content={COPY_OVERRIDES.meta.credentials}
            />
          )}
        </Helmet>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.freeCred}>
                  Credentials are certifications that verify demonstrated
                  knowledge or workplace experience on a topic. Credentials are
                  free to earn, displayed on your profile, and can help you
                  unlock career opportunities, educational content, and more.
                  All credentials are non-accredited and do not count toward
                  continuing medical education.
                </div>
                <div className={styles.credDescription}>
                  To earn an academic credential through our free academy, which
                  includes free courses and assessment quizzes, click here:
                </div>
                <a
                  href={`${academyURL}/certifications`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.brandingLink}
                >
                  Earn Academic & Training Credentials
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <Credentials
                  onDelete={deleteCertificate}
                  credentials={profile?.certificates}
                  isLoading={isLoading}
                />

                {!profile?.certificates?.length && (
                  <div className={styles.noCertificate}>
                    <div className={styles.noCertBadge}>
                      <SVG
                        description="No Certificate"
                        src={BadgeGrey}
                        title="Badge"
                      />
                    </div>
                    <div className={styles.noResumeHeading}>
                      You have no current certifications or licenses added just
                      yet.
                    </div>
                    <div className={styles.noResumeDate}>
                      If you’d like for one to be added please use the button
                      below.
                    </div>
                  </div>
                )}
              </Col>
              <div className={styles.line}></div>
            </Row>
            {/* <Row>
              <Col>
                <div className={styles.credDescription}>
                  To earn a workplace experience credential through our Get
                  Verified Pro partner, click here and follow the prompts to
                  complete your request (requires a third party to verify, such
                  as a former employer, client, educator, etc.):
                </div>
                <a
                  href={FREE_WORK_CREDENTIALS}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn-grey-ghost"
                >
                  Earn Workplace Experience Credentials
                </a>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Resume;
